import { CommonRepositoryAbstract } from '@freddy/common';
import { TenantService } from '../../../core/auth/services/tenant.service';
import { Injectable, inject } from '@angular/core';
import { Team } from '@freddy/models';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class TeamRepository extends CommonRepositoryAbstract<Team> {
  private readonly tenantService = inject(TenantService);

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  protected getDocPath(): string {
    return `/${this.tenantService.getOrganizationPrefixPath()}games/:gameUid/teams`;
  }
}

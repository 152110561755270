import { Injectable, inject } from '@angular/core';
import { CommonRepositoryAbstract } from '@freddy/common';
import { Store } from '@ngxs/store';
import { InGameState } from '../stores/in-game.store';
import { Observable } from 'rxjs';
import { TeamChallenge } from '@freddy/models';
import { Firestore, limit, where } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class TeamChallengeRepository extends CommonRepositoryAbstract<TeamChallenge> {
  private readonly store = inject(Store);

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  getChallenge(
    challengerUid: string,
    opponentUid: string,
    challengeUid: string,
  ): Observable<TeamChallenge[]> {
    const queryConstraints = [
      where('challengerUid', '==', challengerUid),
      where('opponentUid', '==', opponentUid),
      where('challengeUid', '==', challengeUid),
      limit(1),
    ];

    return this.getCollections(undefined, queryConstraints);
  }

  protected getDocPath(): string {
    return `${this.store.selectSnapshot(InGameState.gamePath)}/challenges`;
  }
}

import { Injectable, inject } from '@angular/core';
import { GameRepository } from '../repository/game.repository';
import { Game, TeamRole } from '@freddy/models';
import { firstValueFrom } from 'rxjs';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class GameAccessService {
  private readonly gameRepository = inject(GameRepository);
  private readonly toastService = inject(HotToastService);
  private readonly translateService = inject(TranslateService);


  async checkGameAccess(code: string): Promise<{
    game: Game;
    role: TeamRole;
    organizationSlug: string;
  } | null> {
    const { role, strippedCode } = this.parseGameCode(code);

    const gameDoc = await firstValueFrom(
      this.gameRepository.fetchGame(strippedCode),
    );
    if (!gameDoc) {
      this.toastService.error(
        this.translateService.instant('game.errors.gameNotFound'),
      );
      return null;
    }

    const game = gameDoc.data();
    const organizationSlug = gameDoc.ref.parent.parent?.id;
    if (!game) {
      this.toastService.error(
        this.translateService.instant('game.errors.invalidGame'),
      );
      return null;
    }
    if (!organizationSlug) {
      this.toastService.error(
        this.translateService.instant('game.errors.organizationNotFound'),
      );
      throw new Error('Organization slug not found');
    }

    // Check if ghost role is allowed
    if (role === TeamRole.GHOST && !game.enableGhost) {
      this.toastService.error(
        this.translateService.instant('game.errors.ghostRoleNotEnabled'),
      );
      return null;
    }

    // Check if hunter role is allowed
    if (role === TeamRole.HUNTER && !game.enableHunter) {
      this.toastService.error(
        this.translateService.instant('game.errors.hunterRoleNotEnabled'),
      );
      return null;
    }

    return {
      game,
      role,
      organizationSlug: organizationSlug,
    };
  }

  private parseGameCode(code: string): {
    role: TeamRole;
    strippedCode: string;
  } {
    if (code.endsWith('ADM')) {
      return {
        role: TeamRole.ADMIN,
        strippedCode: code.slice(0, -3),
      };
    }
    if (code.endsWith('GHOST')) {
      return {
        role: TeamRole.GHOST,
        strippedCode: code.slice(0, -5),
      };
    }
    if (code.endsWith('HUNT')) {
      return {
        role: TeamRole.HUNTER,
        strippedCode: code.slice(0, -4),
      };
    }
    return {
      role: TeamRole.PLAYER,
      strippedCode: code,
    };
  }
}

import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  GoToMapAction,
  GoToMissionsCompletedAction,
  ListenAnswersAction,
  ListenGameEndAction,
  ListenMissionCompletionAction,
  SaveOrUploadMissionAnswerAction,
  SubmitMissionAnswerAction,
} from '../../game/actions/game.actions';
import { append, patch } from '@ngxs/store/operators';
import { InGameState } from './in-game.store';
import {
  Answer,
  Game,
  GameStatus,
  Mission,
  MissionTypeEnum,
  Team,
  TeamRole,
} from '@freddy/models';
import { GuidUtils, LaunchMissionAction } from '@freddy/common';
import { AnswerRepository } from '../repository/answer.repository';
import {
  delay,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { MissionService } from '../../game/services/mission.service';
import { TeamRepository } from '../repository/team.repository';
import { combineLatest, firstValueFrom } from 'rxjs';
import { MissionWithLocation } from '../../game/containers/map-view/map-view.component';
import { CommandService } from '../services/command.service';
import { OneTimeDisplayEnum } from '../../game/config/one-time-display.enum';

export const MISSION_STATE_TOKEN = new StateToken<MissionStateModel>('mission');

export interface MissionStateModel {
  answers: Answer[];
  loading: boolean;
}

const defaultFormValue = {
  answers: [],
  loading: false,
};

@State<MissionStateModel>({
  name: MISSION_STATE_TOKEN,
  defaults: {
    ...defaultFormValue,
  },
})
@Injectable()
export class MissionState {
  private readonly router = inject(Router);
  private readonly answerRepository = inject(AnswerRepository);
  private readonly store = inject(Store);
  private readonly zone = inject(NgZone);
  private readonly teamRepository = inject(TeamRepository);
  private readonly missionService = inject(MissionService);
  private readonly commandService = inject(CommandService);

  @Selector()
  static loading(state: MissionStateModel): boolean {
    return state.loading;
  }

  @Selector([InGameState.game])
  static bonusMissions(game: Game | undefined): Mission[] {
    return (
      game?.scenario.missions.filter(
        (mission) => !mission.common.geolocalized,
      ) ?? []
    );
  }

  @Selector([InGameState.game])
  static geolocalizedMissions(game: Game | undefined): MissionWithLocation[] {
    return (game?.scenario.missions.filter(
      (mission) => mission.common.geolocalized,
    ) ?? []) as MissionWithLocation[];
  }

  @Selector([InGameState.game, InGameState.myTeam])
  static nextMission(
    game: Game | undefined,
    myTeam: Team | undefined,
  ): MissionWithLocation | undefined {
    if (!game) {
      return undefined;
    }

    // Check if the game is stopped or has ended
    if (
      game.status === GameStatus.STOPPED ||
      (game.plannedEndDate?.getTime() &&
        game.plannedEndDate.getTime() < new Date().getTime())
    ) {
      return undefined;
    }

    // If the game is ongoing, continue
    if (
      myTeam?.teamNumber === undefined ||
      !game.scenario?.missions ||
      !game.scenario?.missionsOrders
    ) {
      return undefined;
    }

    const missions: Mission[] = game.scenario.missions;
    const missionsOrderData = JSON.parse(game.scenario.missionsOrders);

    // Determine the mission order based on the team's index
    const teamMissionOrder: string[] =
      missionsOrderData[myTeam.teamNumber % missions.length];
    if (!teamMissionOrder) {
      return undefined;
    }

    // Filter missions to those that are geolocalized and not yet accomplished
    const missionsWithLocation = teamMissionOrder
      .map((missionUid: string) =>
        missions.find((mission) => mission.uid === missionUid),
      )
      .filter(
        (mission): mission is Mission =>
          !!mission && mission.common?.geolocalized === true,
      )
      .filter(
        (mission) => !myTeam.missionAccomplished.includes(mission.uid),
      ) as MissionWithLocation[];

    return missionsWithLocation.length > 0
      ? missionsWithLocation[0]
      : undefined;
  }

  static mission(missionUid?: string) {
    return createSelector([InGameState.game], (game: Game | undefined) => {
      return game?.scenario.missions.find(
        (mission) => mission.uid === missionUid,
      );
    });
  }

  @Selector([MissionState.myAnswers, InGameState.game])
  static countNotAnsweredBonusMission(
    answers: Answer[],
    game: Game | undefined,
  ): number {
    return (
      game?.scenario.missions.filter((mission) => {
        const answer = answers.find(
          (answer) => answer.missionUid === mission.uid,
        );
        return !answer && !mission.common.geolocalized;
      }).length ?? 0
    );
  }

  @Selector()
  static answers(state: MissionStateModel): Answer[] {
    return state.answers;
  }

  @Selector([MissionState.answers, InGameState.myTeam])
  static myAnswers(answers: Answer[], myTeam: Team | undefined): Answer[] {
    return answers.filter((answer) => answer.teamUid === myTeam?.uid);
  }

  static specificChallenge(challengeUid: string) {
    return createSelector([InGameState.game], (game: Game | undefined) => {
      return game?.scenario?.challenges?.find(
        (challenge) => challenge.uid === challengeUid,
      );
    });
  }

  static specificAnswer(challengeUid: string) {
    return createSelector([InGameState.game], (game: Game | undefined) => {
      const challenge =
        game?.scenario?.challenges?.find(
          (challenge) => challenge.uid === challengeUid,
        ) ?? null;
      if (challenge) {
        // @ts-ignore
        return challenge.metadata[challenge.metadata.missionType]?.answer;
      }
      return null;
    });
  }

  @Selector([
    MissionState.nextMission,
    MissionState.geolocalizedMissions,
    InGameState.myTeamRole,
  ])
  static visibleMissions(
    nextMission: MissionWithLocation | undefined,
    geolocalizedMissions: MissionWithLocation[],
    myTeamRole: TeamRole,
  ): MissionWithLocation[] {
    if (myTeamRole === TeamRole.GHOST || myTeamRole === TeamRole.HUNTER) {
      return geolocalizedMissions;
    } else {
      return nextMission ? [nextMission] : [];
    }
  }

  @Action(LaunchMissionAction)
  launchMissionAction(
    ctx: StateContext<MissionStateModel>,
    action: LaunchMissionAction,
  ) {
    if (
      this.store
        .selectSnapshot(InGameState.myTeam)
        ?.missionAccomplished?.includes(action.missionUid)
    ) {
      return;
    }
    const missionRoute = [
      'game',
      this.store.selectSnapshot(InGameState.game)?.code,
      'mission',
      action.missionUid,
      'start',
    ];
    if (
      this.router.url.indexOf('map') > -1 &&
      this.router.url.indexOf('mission') === -1
    ) {
      return this.zone.run(() => {
        return this.router.navigate(missionRoute, { skipLocationChange: true });
      });
    }
    return;
  }

  @Action(ListenMissionCompletionAction, { cancelUncompleted: true })
  listenMissionCompletion(ctx: StateContext<MissionStateModel>) {
    return this.store.select(InGameState.myTeam).pipe(
      filter((team): team is Team => !!team),
      switchMap((team) =>
        combineLatest([
          this.store.select(MissionState.nextMission),
          this.store.select(
            InGameState.oneTimeDisplay(OneTimeDisplayEnum.MISSIONS_COMPLETED),
          ),
        ]).pipe(
          takeUntil(
            this.store
              .select(InGameState.gameIsDone)
              .pipe(filter((isDone) => isDone)),
          ),
          filter(
            ([mission, alreadyDisplayed]) =>
              mission === undefined && !alreadyDisplayed,
          ),
          take(1),
          delay(5000),
          map(() => team),
        ),
      ),
      tap((team) => {
        this.commandService.sendCommand({
          uid: GuidUtils.generateUuid(),
          recipientTeamUid: team.uid,
          action: new GoToMissionsCompletedAction(),
          executed: false,
        });
      }),
    );
  }

  @Action(SubmitMissionAnswerAction)
  async submitMissionAnswerAction(
    ctx: StateContext<MissionStateModel>,
    action: SubmitMissionAnswerAction,
  ) {
    ctx.patchState({ loading: true });
    const currentTeamPath = this.store.selectSnapshot(InGameState.teamPath);
    const myTeam = this.store.selectSnapshot(InGameState.myTeam);
    if (currentTeamPath && myTeam) {
      // TODO: Should move to saveOrUploadMissionAnswerAction
      if (action.mission.common.geolocalized) {
        this.teamRepository.update({
          uid: myTeam.uid,
          missionAccomplished: [
            ...(myTeam.missionAccomplished ?? []),
            action.mission.uid,
          ],
        });
      } else {
        this.teamRepository.update({
          uid: myTeam.uid,
          missionBonusAccomplished: [
            ...(myTeam.missionBonusAccomplished ?? []),
            action.mission.uid,
          ],
        });
      }
      // END TODO

      const answer: Answer = {
        missionUid: action.mission.uid,
        answer: action.answer,
        missionType: action.mission.metadata.missionType,
        createdAt: new Date(),
        skipped: action.skipped,
        isEvaluated: action.skipped,
        teamUid: this.store.selectSnapshot(InGameState.myTeam)!.uid,
        uid: GuidUtils.generateUuid(),
      };

      ctx.dispatch(new SaveOrUploadMissionAnswerAction(answer));

      const missionRouteResult = [
        'game',
        this.store.selectSnapshot(InGameState.game)?.code,
        'mission',
        action.mission.uid,
        'result',
      ];

      if (
        action.mission.metadata.missionType === MissionTypeEnum.SLIDING_PUZZLE
      ) {
        return this.store.dispatch(new GoToMapAction());
      }
      return this.router.navigate(missionRouteResult, {
        skipLocationChange: true,
      });
    }
    return;
  }

  @Action(SaveOrUploadMissionAnswerAction)
  async saveOrUploadMissionAnswerAction(
    ctx: StateContext<MissionStateModel>,
    action: SaveOrUploadMissionAnswerAction,
  ) {
    const mission = this.store
      .selectSnapshot(InGameState.game)
      ?.scenario.missions.find((m) => m.uid === action.answer.missionUid);
    if (!mission) {
      return console.error('Mission not found');
    }

    const answer = await this.missionService.handleMissionAnswer(
      action.answer,
      mission,
    );

    await firstValueFrom(
      this.answerRepository.add(action.answer.skipped ? action.answer : answer),
    );

    ctx.setState(
      patch<MissionStateModel>({
        answers: append<Answer>([answer]),
        loading: false,
      }),
    );
  }

  @Action(ListenAnswersAction, { cancelUncompleted: true })
  listenAnswersAction(ctx: StateContext<MissionStateModel>) {
    return this.answerRepository.getCollectionsChanges().pipe(
      tap((answers) => {
        if (answers) {
          ctx.patchState({
            answers: answers,
          });
        }
      }),
    );
  }
}

import { Injectable, inject } from '@angular/core';
import { CommonRepositoryAbstract } from '@freddy/common';
import { Asset } from '@freddy/models';
import { TenantService } from '../auth/services/tenant.service';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class AssetRepository extends CommonRepositoryAbstract<Asset> {
  private readonly tenantService = inject(TenantService);

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  static DOC_PATH = 'assets';

  protected getDocPath(): string {
    return (
      this.tenantService.getOrganizationPrefixPath() + AssetRepository.DOC_PATH
    );
  }
}

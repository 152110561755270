// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'default',
  useEmulators: false,
  version: 'develop',
  production: false,
  sentry: {
    dsn: 'https://a9aa9d80e988394f6501db85fa2c9d55@o4506207716114432.ingest.us.sentry.io/4507981510606848',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    showDialog: true,
  },
  firebase: {
    region: 'europe-west1',
    projectId: 'freddy-931ae',
    databaseURL:
      'https://freddy-931ae-default-rtdb.europe-west1.firebasedatabase.app/',
    appId: '1:876816755465:web:798547ba403b1dcaae01d8',
    storageBucket: 'freddy-931ae.appspot.com',
    apiKey: 'AIzaSyAuyxHTOLb4KTVHvAVzjK5agcMFmkYtGlI',
    authDomain: 'freddy-931ae.firebaseapp.com',
    messagingSenderId: '876816755465',
    measurementId: 'G-06WZWQL0N7',
    recaptcha: '6LcIzlcpAAAAAJ40uUgBrYoHcRjn2QJTY8wr_V1Q',
  },
  mapbox: {
    token:
      'pk.eyJ1IjoiYm9rem9yIiwiYSI6ImNsZTRvenBxbzA1M3k0MXFkb2ZzM2NxbnYifQ.9Mac1d54adqHiqTp000cHg',
    geocoder: {
      countriesActivated: 'fr,nl,be,de',
    },
    style: 'mapbox://styles/mapbox/standard?optimize=true',
  },
  typesense: {
    apiKey: 'freddy-admin',
    host: 'search.freddy.ovh',
    port: 443,
    protocol: 'https',
    scenariosCollectionName: 'scenarios-dev',
    gamesCollectionName: 'games-dev',
  },
  game: {
    defaultRadius: 15,
    defaultPoints: 10,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

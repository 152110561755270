{
  "name": "freddy-admin",
  "version": "1.280.0",
  "scripts": {
    "ng": "ng",
    "start:admin": "ng serve freddy-admin --configuration=local --port 4200",
    "start:pwa": "ng serve freddy --configuration=local --port 4201",
    "start:website": "ng serve website  --port 4202",
    "start:android": "ionic cap run android -l --external",
    "start:ios": "npx cap run ios ",
    "start:android:open": "npx cap open android ",
    "build:android:open": "npx cap build android ",
    "start:android:sync": "npx cap sync android ",
    "build:prod": "ng build models && ng build common && ng build freddy-admin --configuration production && ng build freddy --configuration production",
    "build:development": "ng build models && ng build common && ng build freddy-admin --configuration development && ng build freddy --configuration development",
    "build:preview": "ng build models && ng build common && ng build freddy-admin --configuration development && ng build freddy --configuration development",
    "build:staging": "ng build models && ng build common && ng build freddy-admin --configuration staging && ng build freddy --configuration staging",
    "build:common": "npm run dev:generate-barrels && ng build models && ng build common ",
    "watch:common": "npm run dev:generate-barrels && ng build models --watch && ng build common --watch",
    "watch:admin": "ng build --watch --project  freddy-admin --configuration local -- port 4200",
    "watch:pwa": "ng build --watch --project freddy --configuration local -- port 4201",
    "test": "ng test --project freddy",
    "format:test": "prettier \"projects/**/*.{ts,html,md,scss,json}\" --list-different",
    "format:write": "prettier \"projects/**/*.{ts,html,md,scss,json}\" --write",
    "analyze": "npm run analyze:build &&npm run analyze:process && npm run analyze:serve",
    "analyze:build": "ng build  freddy --source-map",
    "analyze:process": "source-map-explorer dist/freddy/*.js --html dist/report/index.html",
    "analyze:serve": "npx http-server dist/report/ -o -c-1",
    "firebase:ext:export": "firebase ext:export",
    "firebase:indexes:export": "firebase firestore:indexes > firebase/firestore.indexes.json",
    "firebase:rules:deploy": "firebase deploy --only firestore:rules && firebase deploy --only storage && firebase deploy --only database",
    "firebase:rules:deploy:staging": "firebase deploy --only firestore:rules --project staging && firebase deploy --only storage --project staging && firebase deploy --only database --project staging",
    "firebase:rules:deploy:prod": "firebase deploy --only firestore:rules --project prod && firebase deploy --only storage --project prod && firebase deploy --only database --project prod",
    "firebase:functions:deploy": "firebase deploy --only functions",
    "firebase:functions:deploy:dev": "firebase deploy --only functions --project development",
    "firebase:functions:deploy:staging": "firebase deploy --only functions --project staging",
    "firebase:hosting:deploy": "firebase deploy --only hosting",
    "firebase:hosting:deploy:prod": "firebase deploy --only hosting --project prod",
    "firebase:hosting:deploy:dev": "firebase deploy --only hosting --project development",
    "firebase:hosting:deploy:staging": "firebase deploy --only hosting --project staging",
    "firebase:hosting:deploy:freddy-admin:prod": "firebase deploy --only hosting:freddy-admin --project prod",
    "firebase:hosting:deploy:freddy-admin:staging": "firebase deploy --only hosting:freddy-admin --project staging",
    "lint": "ng lint",
    "dev:generate-barrels": "barrelsby -l all -d projects/common/src/lib --delete && barrelsby -l all -d projects/models/src/lib --delete",
    "serve:ssr:website": "node dist/website/server/server.mjs",
    "build:deploy:prod": "npm run build:prod && npm run firebase:hosting:deploy:prod",
    "build:deploy:staging": "npm run build:staging && npm run firebase:hosting:deploy:staging"
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/schematics-cli": "^19.1.4",
    "@angular/animations": "^19.1.3",
    "@angular/cdk": "^19.1.1",
    "@angular/common": "^19.1.3",
    "@angular/compiler": "^19.1.3",
    "@angular/core": "^19.1.3",
    "@angular/fire": "^19.0.0",
    "@angular/forms": "^19.1.3",
    "@angular/platform-browser": "^19.1.3",
    "@angular/platform-browser-dynamic": "^19.1.3",
    "@angular/platform-server": "^19.1.3",
    "@angular/router": "^19.1.3",
    "@angular/ssr": "^19.1.4",
    "@capacitor-community/camera-preview": "^6.0.1",
    "@capacitor/android": "^6.1.2",
    "@capacitor/angular": "^2.0.0",
    "@capacitor/app": "^6.0.1",
    "@capacitor/core": "^6.1.2",
    "@capacitor/device": "^6.0.1",
    "@capacitor/filesystem": "^6.0.1",
    "@capacitor/geolocation": "^6.0.1",
    "@capacitor/local-notifications": "^6.1.0",
    "@capacitor/network": "^6.0.2",
    "@capacitor/splash-screen": "^6.0.2",
    "@ffmpeg/core": "^0.12.6",
    "@ffmpeg/ffmpeg": "^0.12.10",
    "@ionic/angular": "^8.3.1",
    "@ionic/storage": "^4.0.0",
    "@ionic/storage-angular": "^4.0.0",
    "@larscom/ngx-translate-module-loader": "^3.1.2",
    "@mapbox/mapbox-gl-geocoder": "^5.0.1",
    "@ngneat/hot-toast": "^5.0.2",
    "@ngneat/overview": "^3.0.0",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@ngxs/devtools-plugin": "^19.0.0",
    "@ngxs/form-plugin": "^19.0.0",
    "@ngxs/logger-plugin": "^19.0.0",
    "@ngxs/router-plugin": "^19.0.0",
    "@ngxs/storage-plugin": "^19.0.0",
    "@ngxs/store": "^19.0.0",
    "@sentry/angular": "8.37.1",
    "@tailwindcss/forms": "^0.5.3",
    "@turf/turf": "^7.0.0",
    "angular-date-value-accessor": "^3.0.0",
    "angular-shepherd": "^18.0.0",
    "angular-svg-icon": "^19.0.1",
    "angular-svg-icon-preloader": "^8.0.0",
    "async": "^3.2.5",
    "bootstrap": "^5.3.0",
    "bootstrap-icons": "^1.11.3",
    "canvas-confetti": "^1.9.3",
    "capacitor-voice-recorder": "^6.0.3",
    "date-fns": "^3.3.1",
    "express": "^4.18.2",
    "geofire-common": "^6.0.0",
    "intl-tel-input": "^19.0.0",
    "jszip": "^3.10.1",
    "locale-enum": "^1.1.1",
    "lodash.get": "^4.4.2",
    "lodash.set": "^4.3.2",
    "mapbox-gl": "^3.0.0",
    "ng-dynamic-component": "^10.7.0",
    "ng-heroicons": "^12.0.0",
    "ngx-bootstrap": "^19.0.1",
    "ngx-intl-tel-input-bokzor": "^18.0.0",
    "ngx-mapbox-gl-bokzor": "11.0.1",
    "ngx-owl-carousel-o": "^19.0.0",
    "ngx-svg-schematic": "^1.2.3",
    "recordrtc": "^5.6.2",
    "rollbar": "^2.26.2",
    "rxjs": "^7.6.0",
    "short-unique-id": "^4.4.4",
    "simplebar-angular": "^3.2.4",
    "tslib": "^2.3.0",
    "typesense": "^1.5.3",
    "typesense-instantsearch-adapter": "^2.6.0",
    "uuid": "^9.0.0",
    "video.js": "^8.10.0",
    "videojs-record": "^4.6.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.4",
    "@angular-eslint/builder": "^19.0.2",
    "@angular-eslint/eslint-plugin": "^19.0.2",
    "@angular-eslint/eslint-plugin-template": "^19.0.2",
    "@angular-eslint/schematics": "^19.0.2",
    "@angular-eslint/template-parser": "^19.0.2",
    "@angular/cli": "~19.1.4",
    "@angular/compiler-cli": "^19.1.3",
    "@angular/pwa": "^19.1.4",
    "@angular/service-worker": "^19.1.3",
    "@capacitor/cli": "^5.0.0",
    "@commitlint/cli": "^17.7.2",
    "@commitlint/config-conventional": "^17.7.0",
    "@playwright/test": "^1.48.1",
    "@types/async": "^3.2.24",
    "@types/canvas-confetti": "^1.6.4",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~4.3.0",
    "@types/lodash.get": "^4.4.7",
    "@types/lodash.set": "^4.3.7",
    "@types/mapbox__mapbox-gl-geocoder": "^4.7.3",
    "@types/node": "^18.18.0",
    "@types/recordrtc": "^5.6.14",
    "@types/uuid": "^9.0.0",
    "@types/video.js": "^7.3.56",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "autoprefixer": "^10.4.13",
    "barrelsby": "^2.8.0",
    "eslint": "^8.57.0",
    "husky": "^8.0.3",
    "jasmine-core": "~4.5.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "ng-packagr": "^19.0.1",
    "postcss": "^8.4.20",
    "prettier": "^3.2.4",
    "source-map-explorer": "^2.5.3",
    "tailwindcss": "^3.2.4",
    "typescript": "^5.2.0"
  }
}

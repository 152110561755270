import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import {
  finalize,
  map,
  scan,
  switchMap,
  takeWhile,
  tap,
  throttleTime,
} from 'rxjs/operators';
import {
  getAuth,
  indexedDBLocalPersistence,
  setPersistence,
} from 'firebase/auth';
import { Database, onDisconnect, ref, set } from '@angular/fire/database';
import {
  DisconnectPlayerCommandAction,
  ImageCacheService,
  LanguageService,
  LocalStorageService,
  RestartApplicationCommandAction,
} from '@freddy/common';
import {
  Asset,
  GameStatus,
  TeamRole,
  User as FreddyUser,
} from '@freddy/models';
import { NavigationEnd, Router } from '@angular/router';
import {
  AssetsLoadedAction,
  AuthenticateUserAction,
  CheckGameAccessAction,
  DisconnectPlayerAction,
  GameAccessDeniedAction,
  GameEndedAction,
  GameJoinRequestAction,
  ListenCommandsAction,
  ListenRouteActions,
  LoadingGameAssetsAction,
  RedirectToAdminAction,
  RejoinGameAction,
  SelectLanguageAction,
  SetTeamStateAction,
  SetTenantAndOrganizationAction,
  SetUserDataAction,
} from '../../connect/actions/connect.actions';
import {
  catchError,
  combineLatest,
  EMPTY,
  filter,
  firstValueFrom,
  from,
  Observable,
  of,
  throwError,
  timer,
} from 'rxjs';
import { InGameState } from './in-game.store';
import { AssetService } from '../services/asset.service';
import { CommandService } from '../services/command.service';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';
import { Auth, signInAnonymously, User } from '@angular/fire/auth';
import { Locale } from 'locale-enum';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { environment } from '../../../environments/environment';
import { MapPreloaderService } from '../services/map-preloader.service';
import { SoundTypeEnum } from '../../shared/models/Sound';
import { TranslateService } from '@ngx-translate/core';
import { SoundService } from '../services/sound.service';
import { HotToastService } from '@ngneat/hot-toast';
import {
  GoToCompanyLogoAction,
  GoToLanguagesSelectorAction,
} from '../../game/actions/game.actions';
import { GameAccessService } from '../services/game-access.service';
import { GameStateModel } from '../../../../../freddy-admin/src/app/features/game/store/game.store';

export const CONNECT_STATE_TOKEN = new StateToken<ConnectStateModel>('connect');

export interface ConnectStateModel {
  loading: boolean;
  gameLoadingProgress: number;
  assets: Asset[];
  currentLang: Locale;
}

const defaultFormValue = {
  loading: false,
  gameLoadingProgress: 0,
  assets: [],
  currentLang: Locale.en,
};

@State<ConnectStateModel>({
  name: CONNECT_STATE_TOKEN,
  defaults: {
    ...defaultFormValue,
  },
})
@Injectable()
export class ConnectState {
  private readonly firestore = inject(Firestore);
  private readonly imCache = inject(ImageCacheService);
  private auth = inject(Auth);
  private readonly router = inject(Router);
  private readonly database = inject(Database);
  private readonly commandService = inject(CommandService);
  private readonly assetService = inject(AssetService);
  private readonly store = inject(Store);
  private readonly languageService = inject(LanguageService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly mapPreloaderService = inject(MapPreloaderService);
  private readonly translateService = inject(TranslateService);
  private readonly soundService = inject(SoundService);
  private toast = inject(HotToastService);
  private functions = inject(Functions);
  private gameAccessService = inject(GameAccessService);

  @Selector()
  static loading(state: ConnectStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static currentLang(state: ConnectStateModel): Locale {
    return state.currentLang;
  }

  @Selector()
  static gameLoadingProgress(state: ConnectStateModel): number {
    return state.gameLoadingProgress;
  }

  @Selector()
  static locale(state: ConnectStateModel): Locale {
    return state.currentLang;
  }

  static asset(assetUid: string) {
    return createSelector([ConnectState], (state: ConnectStateModel) => {
      return state.assets.find((asset) => asset.uid === assetUid);
    });
  }

  static assetByTypeAndUid(assetUids: string[], type: string) {
    return createSelector([ConnectState], (state: ConnectStateModel) => {
      return state.assets.filter(
        (asset) =>
          assetUids.includes(asset.uid) && asset.metadata?.['type'] === type,
      );
    });
  }

  static assetsByType(assetType: string) {
    return createSelector([ConnectState], (state: ConnectStateModel) => {
      return state.assets.filter(
        (asset) => asset?.metadata?.['type'] === assetType,
      );
    });
  }

  @Action(CheckGameAccessAction)
  async checkGameAccess(
    ctx: StateContext<GameStateModel>,
    action: CheckGameAccessAction,
  ) {
    const accessInfo = await this.gameAccessService.checkGameAccess(
      action.code,
    );

    if (!accessInfo) {
      return ctx.dispatch(new GameAccessDeniedAction('INVALID_CODE'));
    }

    // Check game status
    if (
      (accessInfo.game.status === GameStatus.STOPPED ||
        accessInfo.game.status === GameStatus.DONE) &&
      accessInfo.role !== TeamRole.ADMIN
    ) {
      return ctx.dispatch(new GameEndedAction());
    }

    return ctx.dispatch(
      new GameJoinRequestAction(
        accessInfo.game,
        accessInfo.role,
        accessInfo.organizationSlug,
      ),
    );
  }

  @Action(GoToCompanyLogoAction)
  goToCompanyLogoAction() {
    const game = this.store.selectSnapshot(InGameState.game);
    return from(this.router.navigate(['game', game?.code, 'intro']));
  }

  @Action(GoToLanguagesSelectorAction)
  goToLanguagesSelectorAction() {
    const game = this.store.selectSnapshot(InGameState.game);
    return from(this.router.navigate(['game', game?.code]));
  }

  @Action(GameEndedAction)
  async gameIsFinishedAction() {
    this.toast.error(
      this.translateService.instant('game.inGameStore.gameIsFinishedAction'),
    );
    this.store.dispatch(new DisconnectPlayerAction());
    return this.router.navigate(['join']);
  }

  @Action(GameAccessDeniedAction)
  gameAccessDenied() {
    this.soundService.playSound(SoundTypeEnum.MINOR_ERROR);
    this.toast.error(
      this.translateService.instant('game.inGameStore.joinGameWrongCodeAction'),
    );
  }

  @Action(SelectLanguageAction)
  selectLanguageAction(
    ctx: StateContext<ConnectStateModel>,
    action: SelectLanguageAction,
  ) {
    const lang = action.lang.split('-')[0].toLowerCase();
    this.languageService.setLanguage(lang);
    ctx.patchState({
      currentLang: action.lang,
    });
  }

  @Action(ListenCommandsAction, { cancelUncompleted: true })
  listenCommandsActions(
    ctx: StateContext<ConnectStateModel>,
    action: ListenCommandsAction,
  ) {
    const teamUid = action.team.uid;
    console.info('Listening commands for team', teamUid);
    return this.commandService.listenForCommands(teamUid);
  }

  @Action(ListenRouteActions, { cancelUncompleted: true })
  listenRouteActions(ctx: StateContext<ConnectStateModel>) {
    const gameId = this.store.selectSnapshot(InGameState.game)?.uid;
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      switchMap((event) => {
        const userUid = this.store.selectSnapshot(InGameState.myTeam)?.userUid;
        if (!userUid) of();
        const currentState = this.determineCurrentState(
          event.urlAfterRedirects,
        );
        console.trace(`Updating userUid (${userUid}) state:`, currentState);
        const positionRef = ref(
          this.database,
          `games/${gameId}/status/${userUid}`,
        );
        ctx.dispatch(new SetTeamStateAction(currentState));
        return fromPromise(
          set(positionRef, {
            currentPath: event.urlAfterRedirects,
            currentState,
          }),
        );
      }),
    );
  }

  @Action(RedirectToAdminAction)
  redirectToAdminAction(
    ctx: StateContext<ConnectStateModel>,
    action: RedirectToAdminAction,
  ) {
    const callable = httpsCallable<
      { gameCode: string },
      { customToken: string }
    >(this.functions, 'createevaluatortoken');
    return fromPromise(
      callable({
        gameCode: action.game.code,
      }),
    ).pipe(
      tap(async (response) => {
        const tenantId = (await this.getConfig(action.organizationSlug))
          ?.tenantId;

        const baseUrl = `${environment.admin.url}/game/control/${action.game.uid}/`;
        const tokenParam = `token=${response.data.customToken}`;
        const tenantParam = tenantId
          ? `&tenantId=${tenantId}&organization=${action.organizationSlug}`
          : '';

        const fullUrl = `${baseUrl}?${tokenParam}${tenantParam}`;
        console.log('Redirecting to', fullUrl);
        window.location.href = fullUrl;
      }),
    );
  }

  @Action(LoadingGameAssetsAction)
  loadGameAction(
    ctx: StateContext<ConnectStateModel>,
    action: LoadingGameAssetsAction,
  ): Observable<boolean> {
    return from(this.router.navigate(['/loading'])).pipe(
      // Handle navigation result
      switchMap((navigationResult) => {
        if (!navigationResult) {
          throw new Error('Navigation to loading screen failed');
        }
        return this.assetService.getAssets(action.game);
      }),

      // Save assets to state and start loading processes
      tap((assets) => ctx.patchState({ assets })),
      switchMap((assets) => {
        // Create observables for both loading processes
        const imageCaching$ = this.cacheGameAssets(ctx, assets);
        const tilePreloading$ = from(
          this.mapPreloaderService.preloadTiles(
            action.game.scenario.location.center,
          ),
        );

        // Combine both loading processes
        return combineLatest([imageCaching$, tilePreloading$]);
      }),

      // Map to success result
      map(() => true),

      // Handle any errors in the pipeline
      catchError((error) => {
        console.error('Failed to load game assets:', error);
        return of(false);
      }),
    );
  }

  /**
   * Creates an observable stream for caching game assets and updating progress
   */
  private cacheGameAssets(
    ctx: StateContext<ConnectStateModel>,
    assets: Asset[],
  ): Observable<void> {
    return from(
      this.imCache.cacheImages(assets.map((asset) => asset.path)),
    ).pipe(
      switchMap((cacheProgress) => cacheProgress),

      // Throttle progress updates
      throttleTime(16), // Limit to ~60fps

      // Smooth out progress updates
      scan((acc, progress) => {
        const step = Math.max(0.5, Math.abs(progress - acc) / 10);
        if (Math.abs(progress - acc) < 0.1) return progress;
        return acc + (progress > acc ? step : -step);
      }, 0),

      // Update state with smoothed progress
      tap((progress) => {
        ctx.patchState({ gameLoadingProgress: progress });
      }),

      // Add a small delay at completion for smooth transition
      finalize(() => {
        timer(1000)
          .pipe(tap(() => ctx.dispatch(new AssetsLoadedAction())))
          .subscribe();
      }),

      takeWhile((progress) => progress < 100, true),
      map(() => void 0),
    );
  }

  @Action(RejoinGameAction)
  async rejoinGameAction() {
    const gameCode = await this.localStorageService.get('gameCode');
    if (gameCode) {
      return this.store.dispatch(new CheckGameAccessAction(gameCode));
    }
    return;
  }

  @Action(DisconnectPlayerAction)
  disconnectPlayerAction() {
    this.localStorageService.remove('gameCode');
    return from(getAuth().signOut());
  }

  @Action(AuthenticateUserAction)
  setTenantAndAuthenticateUser(
    ctx: StateContext<ConnectStateModel>,
    action: AuthenticateUserAction,
  ): Observable<void> {
    return from(this.getConfig(action.organizationSlug)).pipe(
      switchMap((config) => {
        getAuth().tenantId = config?.tenantId ?? null;
        return from(setPersistence(this.auth, indexedDBLocalPersistence));
      }),
      switchMap(() =>
        ctx.dispatch(
          new SetTenantAndOrganizationAction({
            tenantId: getAuth().tenantId,
            organizationSlug: action.organizationSlug,
          }),
        ),
      ),
      switchMap(() => from(signInAnonymously(this.auth))),
      switchMap((userCredential) => {
        if (userCredential.user) {
          return from(this.handleUserConnection(ctx, userCredential.user));
        }
        return EMPTY;
      }),
      catchError((error) => {
        console.error('Error during authentication process:', error);
        // You might want to dispatch an error action here
        // ctx.dispatch(new AuthenticationErrorAction(error));
        return throwError(() => new Error('Authentication failed'));
      }),
      map(() => void 0), // Ensures the Observable completes with void
    );
  }

  getPublicConfigPath(organizationSlug: string): string {
    return `organizations/${organizationSlug}/public/config`;
  }

  @Action(RestartApplicationCommandAction)
  restartApplicationCommandAction() {
    location.reload();
  }

  @Action(DisconnectPlayerCommandAction)
  disconnectPlayerCommandAction() {
    return this.disconnectPlayerAction().pipe(
      tap(() => {
        this.router.navigate(['join']);
      }),
    );
  }

  private determineCurrentState(url: string): 'AVAILABLE' | 'UNKNOWN' {
    return url.includes('/map') || url.endsWith('/challenges')
      ? 'AVAILABLE'
      : 'UNKNOWN';
  }

  private async getConfig(
    organizationSlug: string,
  ): Promise<{ tenantId: string } | undefined> {
    const docRef = doc(
      this.firestore,
      this.getPublicConfigPath(organizationSlug),
    );
    const docSnapshot = await getDoc(docRef);
    return docSnapshot.data() as any;
  }

  private async handleUserConnection(
    ctx: StateContext<ConnectStateModel>,
    user: User,
  ): Promise<void> {
    const realTimeRef = ref(this.database, `/status/${user.uid}`);
    await onDisconnect(realTimeRef).set({ state: 'offline' });
    await set(realTimeRef, { state: 'online' });

    const token = await user.getIdTokenResult();
    const organization = token?.claims['organization'] as string;

    const userData: FreddyUser = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      organization: organization,
      lastSeen: new Date().getTime(),
      createdAt: new Date(),
    };

    ctx.dispatch(new SetUserDataAction(userData));
  }
}

import { Injectable, inject } from '@angular/core';
import {
  ColorUtils,
  CommonRepositoryAbstract,
  FirebaseStorageService,
  GuidUtils,
  WithUid,
} from '@freddy/common';
import { InGameState } from '../stores/in-game.store';
import { Team, TeamRole } from '@freddy/models';
import { Firestore, QueryConstraint, where } from '@angular/fire/firestore';
import { Store } from '@ngxs/store';
import { map, switchMap, take } from 'rxjs/operators';
import { firstValueFrom, from, Observable } from 'rxjs';
import { Locale } from 'locale-enum';

@Injectable({
  providedIn: 'root',
})
export class TeamRepository extends CommonRepositoryAbstract<Team> {
  private readonly store = inject(Store);
  private readonly firebaseStorageService = inject(FirebaseStorageService);

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  getTeamByUid(teamUid: string): Observable<Team | undefined> {
    const queryConstraints: QueryConstraint[] = [where('uid', '==', teamUid)];

    return this.getCollections(undefined, queryConstraints).pipe(
      map((teams) => (teams.length > 0 ? teams[0] : undefined)),
    );
  }

  getTeamByUserUid(userUid: string): Observable<Team | undefined> {
    const queryConstraints: QueryConstraint[] = [
      where('userUid', '==', userUid),
    ];

    return this.getCollections(undefined, queryConstraints).pipe(
      map((teams) => (teams.length > 0 ? teams[0] : undefined)),
    );
  }

  async createSpecialRoleTeam(role: TeamRole, language: Locale): Promise<Team> {
    if (role !== TeamRole.GHOST && role !== TeamRole.HUNTER) {
      throw new Error('Invalid role for special team creation');
    }

    const game = this.store.selectSnapshot(InGameState.game);
    const user = this.store.selectSnapshot(InGameState.user);

    if (!game || !user) {
      throw new Error('Game or user not found');
    }

    const shortCode = GuidUtils.generateShortCode().substring(0, 3);
    const rolePrefix = role === TeamRole.GHOST ? 'Ghost' : 'Hunter';

    const team: Team = {
      uid: GuidUtils.generateUuid(),
      userUid: user.uid,
      teamName: `${rolePrefix}-${shortCode}`,
      teamPhoto:
        role === TeamRole.GHOST
          ? 'assets/img/ghost.webp'
          : 'assets/img/hunter.webp',
      currentState: 'AVAILABLE',
      online: true,
      lastSeen: new Date().getTime(),
      role,
      color: '#FFFFFF',
      language,
      points: 0,
      missionAccomplished: [],
      challengeAccomplished: [],
      missionBonusAccomplished: [],
      ...(role === TeamRole.HUNTER ? { lastAttacks: {} } : {}),
    };

    // Create the team in the repository
    return firstValueFrom(
      this.create(team, {
        gameUid: game.uid,
      }),
    );
  }

  createPlayerTeam(
    teamName: string,
    photoPathBlob: string,
    language: Locale,
  ): Observable<Team> {
    const gamePath = this.store.selectSnapshot(InGameState.gamePath);
    const user = this.store.selectSnapshot(InGameState.user);

    if (!gamePath || !user) {
      throw new Error('Game or user not found');
    }

    return from(
      this.firebaseStorageService.uploadPathBlob(gamePath, photoPathBlob),
    ).pipe(
      map((fileUpload) => {
        const team: WithUid<Omit<Team, 'color'>> = {
          uid: GuidUtils.generateUuid(),
          userUid: user.uid,
          teamName,
          teamPhoto: fileUpload.filePath,
          currentState: 'AVAILABLE',
          online: true,
          lastSeen: new Date().getTime(),
          role: TeamRole.PLAYER,
          language,
          points: 0,
          missionAccomplished: [],
          challengeAccomplished: [],
          missionBonusAccomplished: [],
        };

        return team;
      }),
      switchMap((team) => this.addPlayerTeam(team)),
    );
  }

  protected getDocPath(): string {
    return `${this.store.selectSnapshot(InGameState.gamePath)}/teams`;
  }

  private addPlayerTeam(
    teamData: WithUid<Omit<Team, 'color'>>,
  ): Observable<Team> {
    return this.getCollections().pipe(
      take(1),
      map((teams) => teams.length),
      switchMap((teamCount) => {
        const assignedColor = ColorUtils.getColor(teamCount);
        const newTeamData: WithUid<Team> = {
          ...teamData,
          color: assignedColor,
        };
        return this.set(newTeamData).pipe(map(() => newTeamData));
      }),
    );
  }
}

import { Injectable, inject } from '@angular/core';
import { OrganizationForm } from '../store/organization.store';
import { MissingIdError } from '../../../shared/errors/missing-id.error';
import { WithUid, CommonRepositoryAbstract } from '@freddy/common';
import { Organization } from '@freddy/models';
import { catchError, firstValueFrom, Observable, of } from 'rxjs';
import { Firestore, limit, where } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable()
export class OrganizationRepository extends CommonRepositoryAbstract<Organization> {
  static DOC_PATH = 'organizations';

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  getDocPath(): string {
    return OrganizationRepository.DOC_PATH;
  }

  updateOrganization(organizationForm: OrganizationForm): Observable<void> {
    if (organizationForm.uid) {
      const organization: WithUid<Organization> = {
        ...organizationForm,
        uid: organizationForm.uid,
      };
      return super.update(organization);
    } else {
      throw new MissingIdError();
    }
  }

  checkOrganizationSlugExists(organizationSlug: string): Observable<boolean> {
    return this.getOrganizationBySlug(organizationSlug).pipe(
      map((organization) => !!organization),
    );
  }

  getOrganizationBySlug(
    organizationSlug: string,
  ): Observable<Organization | undefined> {
    return this.get(organizationSlug).pipe(
      catchError((err, caught) => {
        return of(undefined);
      }),
    );
  }
}

import { Game, Team, TeamRole, TeamState, User } from '@freddy/models';
import { OrganizationAndTenant } from '../models/organization';
import { Locale } from 'locale-enum';

export class RedirectToAdminAction {
  static readonly type = '[Connect] Redirect to the admin console of the game';
  constructor(
    public readonly game: Game,
    public readonly organizationSlug: string,
  ) {}
}

export class ConfirmLanguageAction {
  static readonly type = '[Game] Confirm language selection';
}

export class CheckGameAccessAction {
  static readonly type = '[Game] Check game access';
  constructor(public readonly code: string) {}
}

export class NavigateToStartGameScreen {
  static readonly type = '[Game] Navigate to start game screen';
  constructor() {}
}

export class GameJoinRequestAction {
  static readonly type = '[Game] Join request';
  constructor(
    public readonly game: Game,
    public readonly role: TeamRole,
    public readonly organizationSlug: string,
  ) {}
}

export class RejoinGameAction {
  static readonly type = '[Game] Rejoin game';
  constructor() {}
}

export class GameAccessDeniedAction {
  static readonly type = '[Game] Access denied';
  constructor(public readonly reason: string) {}
}

export class GameEndedAction {
  static readonly type = '[Game] Game ended';
  constructor() {}
}

export class LoadingGameAssetsAction {
  static readonly type = '[Game] Loading game assets';
  constructor(public readonly game: Game) {}
}

export class AssetsLoadedAction {
  static readonly type = '[Game] Assets loaded';
  constructor() {}
}

export class DisconnectPlayerAction {
  static readonly type = '[Connect] Disconnect player';
  constructor() {}
}

export class AuthenticateUserAction {
  static readonly type = '[Connect] Authenticate user';
  constructor(public readonly organizationSlug: string) {}
}

export class SetTenantAndOrganizationAction {
  static readonly type = '[Connect] Set tenant and organization';
  constructor(public readonly organization: OrganizationAndTenant) {}
}

export class SetUserDataAction {
  static readonly type = '[Connect] Set user data';
  constructor(public readonly user: User) {}
}

export class SetTeamStateAction {
  static readonly type = '[Connect] Set team state';
  constructor(public readonly teamState: TeamState) {}
}

export class ListenCommandsAction {
  static readonly type = '[Connect] Will Listen to the commands collection';
  constructor(public readonly team: Team) {}
}

export class ListenRouteActions {
  static readonly type = '[Connect] Will Listen to the angular route changes';
}

export class SelectLanguageAction {
  static readonly type = '[Connect] Select language';
  constructor(public readonly lang: Locale) {}
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  NgZone,
} from '@angular/core';
import { environment } from '../environments/environment';
import { pageTransitionAnimations } from './core/utils/animation.utils';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService, LocalStorageService, UpdateService } from 'common';
import { Locale } from 'locale-enum';
import { ThemeService } from './core/services/theme.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

import packageJson from '../../../../package.json';
import {
  Platform,
  IonApp,
  IonContent,
  IonRouterOutlet,
} from '@ionic/angular/standalone';
import { filter } from 'rxjs';
import { take } from 'rxjs/operators';
const appVersion = packageJson.version;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonApp, IonContent, IonRouterOutlet],
})
export class AppComponent implements AfterViewInit {
  private translate = inject(TranslateService);
  private languageService = inject(LanguageService);
  private themeService = inject(ThemeService);
  private readonly updateService = inject(UpdateService);
  private localStorageService = inject(LocalStorageService);
  private readonly activatedRoute = inject(ActivatedRoute);
  readonly platform = inject(Platform);

  ngAfterViewInit(): void {
    const splash = document.querySelector('.splash-screen');

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        // Hide splash only after module is loaded
        splash?.classList.add('hide-splash');
        if (this.activatedRoute.snapshot.queryParams['code']) {
          this.localStorageService.set(
            'gameCode',
            this.activatedRoute.snapshot.queryParams['code'],
          );
        }
      });
  }

  constructor(
    private zone: NgZone,
    private router: Router,
  ) {
    this.initializeTranslation();
    this.initializeApp();
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split(environment.appDomain).pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  title = 'freddy';
  commit = environment.version;
  tag = appVersion;

  private initializeTranslation(): void {
    // Subscribe to the list of available languages
    this.languageService
      .getAvailableLanguages()
      .subscribe((availableLanguages) => {
        const browserLang = environment.production ? navigator.language : 'en';

        // Check if the browser language is available
        let supportedLang = availableLanguages.find((lang) =>
          browserLang.startsWith(lang),
        );

        // If the browser language is not supported, use English as default
        supportedLang = supportedLang ? supportedLang : Locale.en;

        // Set the default language and the current language
        this.translate.setDefaultLang(Locale.en); // English as a fallback
        this.translate.use(supportedLang);
      });
  }

  protected readonly pageTransitionAnimations = pageTransitionAnimations;
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import confetti from 'canvas-confetti';

@Injectable({
  providedIn: 'root',
})
export class GameEffectsService {
  private createBombEffect(
    x: number,
    y: number,
    size: 'large' | 'small' = 'large',
  ) {
    // Create bomb emoji shape
    const scalar = size === 'large' ? 3 : 2;
    const bomb = confetti.shapeFromText({ text: '💣', scalar });
    const explosion = confetti.shapeFromText({ text: '💥', scalar });

    const defaults = {
      spread: 360,
      ticks: 60,
      gravity: 0,
      decay: 0.94,
      startVelocity: 25,
      origin: { x, y },
    };

    // Bomb emojis
    confetti({
      ...defaults,
      particleCount: size === 'large' ? 25 : 15,
      shapes: [bomb],
      scalar,
    });

    // Explosion emojis
    confetti({
      ...defaults,
      particleCount: size === 'large' ? 20 : 10,
      shapes: [explosion],
      scalar: scalar * 0.8,
    });

    // Particle effects
    confetti({
      ...defaults,
      particleCount: size === 'large' ? 40 : 20,
      shapes: ['circle'],
      scalar: scalar / 3,
      colors: ['#ff0000', '#ff4400', '#ff8800'],
      startVelocity: 35,
    });
  }

  private createExplosionWave(x: number, y: number) {
    const explosion = confetti.shapeFromText({ text: '💥', scalar: 2 });

    // Create a wave of explosion emojis
    for (let i = 0; i < 8; i++) {
      const angle = (i * Math.PI * 2) / 8;
      const distance = 0.15;

      setTimeout(() => {
        confetti({
          particleCount: 3,
          angle: (angle * 180) / Math.PI,
          spread: 20,
          origin: {
            x: x + Math.cos(angle) * distance,
            y: y + Math.sin(angle) * distance,
          },
          shapes: [explosion],
          ticks: 80,
          gravity: 0,
          decay: 0.94,
          scalar: 1.5,
          startVelocity: 20,
        });
      }, i * 50);
    }
  }

  showAttackAnimation(): Observable<void> {
    return new Observable<void>((observer) => {
      const duration = 2000;
      const positions = [
        [0.5, 0.5], // Center
        [0.4, 0.4], // Top left
        [0.6, 0.4], // Top right
        [0.4, 0.6], // Bottom left
        [0.6, 0.6], // Bottom right
      ];

      // Create main bomb impacts
      positions.forEach(([x, y], i) => {
        setTimeout(() => {
          this.createBombEffect(x, y, i === 0 ? 'large' : 'small');

          // Add explosion wave after each impact
          setTimeout(() => {
            this.createExplosionWave(x, y);
          }, 100);
        }, i * 200);
      });

      setTimeout(() => {
        observer.next();
        observer.complete();
      }, duration);
    });
  }

  triggerAttackEffects(isHunter: boolean) {
    if (isHunter) {
      // Hunter sees one big centered bomb attack
      const x = 0.5,
        y = 0.5;
      this.createBombEffect(x, y, 'large');

      // Followed by explosion waves
      setTimeout(() => {
        this.createExplosionWave(x, y);
      }, 100);
    } else {
      // Target sees multiple bomb impacts
      const positions = [
        [0.5, 0.5],
        [0.3, 0.3],
        [0.7, 0.4],
        [0.4, 0.7],
        [0.6, 0.6],
      ];

      positions.forEach(([x, y], i) => {
        setTimeout(() => {
          this.createBombEffect(x, y, 'small');

          // Add explosion wave after each impact
          setTimeout(() => {
            this.createExplosionWave(x, y);
          }, 100);
        }, i * 150);
      });
    }
  }

  showVictoryAnimation(): Observable<void> {
    return new Observable<void>((observer) => {
      const duration = 2500;

      // Fire initial burst
      this.createVictoryBurst(0.5, 0.3); // Center top

      // Create side bursts with delay
      setTimeout(() => {
        this.createVictoryBurst(0.2, 0.5); // Left
        this.createVictoryBurst(0.8, 0.5); // Right
      }, 250);

      // Create corner bursts
      setTimeout(() => {
        this.createVictoryBurst(0.2, 0.2); // Top left
        this.createVictoryBurst(0.8, 0.2); // Top right
        this.createVictoryBurst(0.2, 0.8); // Bottom left
        this.createVictoryBurst(0.8, 0.8); // Bottom right
      }, 500);

      // Final center burst
      setTimeout(() => {
        this.createVictoryCenterBurst();
      }, 1000);

      setTimeout(() => {
        observer.next();
        observer.complete();
      }, duration);
    });
  }

  private createVictoryBurst(x: number, y: number): void {
    // Create star and sparkle emojis
    const star = confetti.shapeFromText({ text: '⭐', scalar: 2 });
    const sparkle = confetti.shapeFromText({ text: '✨', scalar: 2 });

    // Celebratory colors
    const colors = ['#ffd700', '#ff6b6b', '#4ecdc4', '#45b7d1', '#96ceb4'];

    // Base confetti settings
    const defaults = {
      origin: { x, y },
      gravity: 0.7,
      decay: 0.94,
      ticks: 120,
    };

    // Burst of stars
    confetti({
      ...defaults,
      particleCount: 15,
      spread: 45,
      startVelocity: 30,
      shapes: [star],
      colors: colors,
    });

    // Sparkle effect
    confetti({
      ...defaults,
      particleCount: 10,
      spread: 60,
      startVelocity: 25,
      shapes: [sparkle],
      colors: ['#ffffff', '#ffeb3b'],
    });

    // Regular confetti pieces
    confetti({
      ...defaults,
      particleCount: 30,
      spread: 70,
      startVelocity: 35,
      colors: colors,
    });
  }

  private createVictoryCenterBurst(): void {
    // Trophy and celebration emojis
    const trophy = confetti.shapeFromText({ text: '🏆', scalar: 3 });
    const party = confetti.shapeFromText({ text: '🎉', scalar: 2.5 });

    // Create a grand finale burst
    const colors = ['#ffd700', '#ff6b6b', '#4ecdc4', '#45b7d1', '#96ceb4'];

    // Trophy burst
    confetti({
      origin: { x: 0.5, y: 0.5 },
      particleCount: 1,
      spread: 0,
      startVelocity: 0,
      gravity: 0,
      shapes: [trophy],
      ticks: 200,
      decay: 0.96,
    });

    // Party poppers
    for (let i = 0; i < 4; i++) {
      setTimeout(() => {
        confetti({
          origin: { x: 0.5, y: 0.5 },
          particleCount: 8,
          angle: i * 90,
          spread: 30,
          startVelocity: 40,
          shapes: [party],
          ticks: 150,
          gravity: 0.5,
          decay: 0.94,
        });
      }, i * 100);
    }

    // Spiral confetti
    for (let i = 0; i < 12; i++) {
      setTimeout(() => {
        confetti({
          origin: { x: 0.5, y: 0.5 },
          particleCount: 15,
          angle: i * 30,
          spread: 20,
          startVelocity: 30,
          colors: colors,
          ticks: 150,
          gravity: 0.4,
          decay: 0.92,
        });
      }, i * 50);
    }
  }
}

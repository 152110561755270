import { Injectable, inject } from '@angular/core';
import { Asset, Challenge, Game, Mission } from '@freddy/models';
import { catchError, combineLatest, mergeMap, Observable, toArray } from 'rxjs';
import { take } from 'rxjs/operators';
import { AssetRepository } from '../repository/asset.repository';
import { Store } from '@ngxs/store';
import { ConnectState } from '../stores/connect.store';
import { QueryConstraint, where } from '@angular/fire/firestore';
import { AssetsFirebaseId } from '../../../../../models/src/lib';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private store = inject(Store);
  private readonly assetRepository = inject(AssetRepository);


  getAssets(game: Game, type?: string): Observable<Asset[]> {
    // Helper function to split array into chunks
    const chunkArray = (array: any[], size: number): any[][] => {
      const nonEmptyArray = array.filter((value) => !!value);
      return nonEmptyArray
        .filter((value) => !!value)
        .reduce(
          (acc, _, index) =>
            index % size
              ? acc
              : [...acc, nonEmptyArray.slice(index, index + size)],
          [],
        );
    };

    // Split the game.assets array into chunks of 10
    const assetChunks = chunkArray(
      [
        ...game.scenario.missions.flatMap((m) => m.assets),
        ...game.scenario.challenges.flatMap((m) => m.assets),
        game.companyLogo,
      ],
      10,
    );
    // Map each chunk to a Firestore query
    const queries = assetChunks.map((chunk) => {
      const queryConstraints: QueryConstraint[] = [where('uid', 'in', chunk)];

      if (type) {
        queryConstraints.push(where('metadata.type', '==', type));
      }

      // Directly passing QueryConstraint[] to getCollections
      return this.assetRepository.getCollections(undefined, queryConstraints);
    });

    // Combine and process the query results
    return combineLatest(queries).pipe(
      take(1),
      mergeMap((docsArray) => docsArray.flat()),
      toArray(),
      catchError((error: any) => {
        console.log('Error getting assets: ', error);
        return [];
      }),
    );
  }

  getAssetById(assetId: string): Asset | undefined {
    return this.store.selectSnapshot(ConnectState.asset(assetId));
  }

  getAssetsByType(assets: AssetsFirebaseId[], type: string): Asset[] {
    return this.store.selectSnapshot(
      ConnectState.assetByTypeAndUid(assets, type),
    );
  }
}

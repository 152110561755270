import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable, inject } from '@angular/core';

import { FirebaseChat, FirebaseChatMessage, Game, Team } from '@freddy/models';
import { ChatRepository } from '../../core/repository/chat.repository';
import {
  ListenChatChangesAction,
  SaveMessageAction,
  SelectChat,
  SelectOrCreateChatAction,
} from '../actions/chat.actions';
import { InGameState } from '../../core/stores/in-game.store';
import { switchMap, tap } from 'rxjs/operators';
import { ChatMessageRepository } from '../../core/repository/chat-message.repository';
import { Conversation, ConversationMessage } from '../chat.model';
import {
  catchError,
  distinctUntilChanged,
  EMPTY,
  filter,
  firstValueFrom,
} from 'rxjs';
import { GuidUtils } from '@freddy/common';

export const CHAT_STATE_TOKEN = new StateToken<ChatStateModel>('chat');

export interface ChatStateModel {
  chats: FirebaseChat[];
  messages: {
    [key: string]: FirebaseChatMessage[];
  };
  loading: boolean;
  selectedChat?: FirebaseChat;
}

const defaultFormValue = {
  chats: [],
  messages: {},
  loading: false,
};

@State<ChatStateModel>({
  name: CHAT_STATE_TOKEN,
  defaults: {
    ...defaultFormValue,
  },
})
@Injectable()
export class ChatState {
  private readonly chatRepository = inject(ChatRepository);
  private readonly chatMessageRepository = inject(ChatMessageRepository);
  private readonly store = inject(Store);


  @Selector([InGameState.game])
  static chatInterTeamsEnabled(game: Game | undefined): boolean {
    return !!game?.enableInterTeamChat;
  }

  @Selector([ChatState, InGameState.myTeam, InGameState.teams])
  static conversations(
    state: ChatStateModel,
    myTeam: Team | undefined,
    teams: Team[],
  ): Conversation[] {
    if (!myTeam) return [];
    return state.chats.map((chat) => {
      return {
        id: chat.uid,
        name: chat.name
          ? chat.name
          : (() => {
              const otherParticipant = chat.participants.find(
                (p) => p !== myTeam.uid,
              );
              if (!otherParticipant) return;
              const team = teams.find((t) => t.uid === otherParticipant);
              return team?.teamName ?? 'ADMIN';
            })(),
        profilePicture: (() => {
          if (chat.participants.length === 2) {
            const otherParticipant = chat.participants.find(
              (p) => p !== myTeam.uid,
            );
            if (!otherParticipant) return;
            const team = teams.find((t) => t.uid === otherParticipant);
            return team ? team.teamPhoto : undefined;
          }
          return undefined;
        })(),
        status: (() => {
          const otherParticipant = chat.participants.find(
            (p) => p !== myTeam.uid,
          );
          if (!otherParticipant) return 'unknown';
          const team = teams.find((t) => t.uid === otherParticipant);
          return team ? (team.online ? 'online' : 'offline') : 'unknown';
        })(),
        unRead: !chat.readBy?.includes(myTeam.uid) && !!chat.lastMessageAt,
        lastMessage: chat.lastMessage,
        lastMessageAt: chat.lastMessageAt,
        isTyping: chat.isTyping,
        isChannel: chat.isChannel,
      };
    });
  }

  @Selector([ChatState.conversations])
  static unreadConversations(conversations: Conversation[]): number {
    return conversations.filter((conversation) => conversation.unRead).length;
  }

  @Selector([ChatState, ChatState.conversations])
  static currentConversation(
    state: ChatStateModel,
    conversations: Conversation[],
  ): Conversation | undefined {
    return conversations.find(
      (conversation) => conversation.id === state.selectedChat?.uid,
    );
  }

  @Selector([ChatState, InGameState.myTeam, InGameState.teams])
  static messages(
    state: ChatStateModel,
    myTeam: Team | undefined,
    teams: Team[],
  ): ConversationMessage[] {
    const getMessages = (chatUid?: string) => {
      if (chatUid && state.messages[chatUid]) {
        return state.messages[chatUid];
      }
      return [];
    };

    return getMessages(state.selectedChat?.uid).map((message) => {
      return {
        id: message.uid,
        message: message.message,
        time: message.createdAt,
        profilePicture: teams.find((team) => team.uid === message.senderUid)
          ?.teamPhoto,
        senderName:
          teams.find((team) => team.uid === message.senderUid)?.teamName ??
          'ADMIN',
        align: message.senderUid === myTeam?.uid ? 'right' : 'left',
        metadata: message.metadata,
      };
    });
  }

  @Action(ListenChatChangesAction)
  listenChatChangesAction(
    ctx: StateContext<ChatStateModel>,
    action: ListenChatChangesAction,
  ) {
    return this.store.select(InGameState.myTeam).pipe(
      filter((team): team is Team => !!team),
      distinctUntilChanged((a, b) => a.uid === b.uid),
      switchMap((team) =>
        this.chatRepository.getChats(team.uid).pipe(
          tap((chats) => {
            ctx.patchState({
              chats: chats,
            });
          }),
        ),
      ),
    );
  }

  @Action(SelectOrCreateChatAction)
  async selectOrCreateChat(
    ctx: StateContext<ChatStateModel>,
    action: SelectOrCreateChatAction,
  ) {
    const state = ctx.getState();
    const receiver = this.store.selectSnapshot(
      InGameState.team(action.teamUid),
    );
    const selectedChat = state.chats.find(
      (chat) => !chat.isChannel && chat.participants.includes(action.teamUid),
    );
    if (selectedChat) {
      ctx.dispatch(new SelectChat(selectedChat.uid));
    } else if (receiver || action.teamUid === 'ADMIN') {
      const chat: FirebaseChat = {
        uid: GuidUtils.generateUuid(),
        participants: [
          action.teamUid,
          this.store.selectSnapshot(InGameState.myTeam)!.uid,
        ],
        readBy: [],
        lastMessageAt: null,
        isTyping: false,
        isChannel: false,
      };

      this.chatRepository.create(chat);
      ctx.dispatch(new SelectChat(chat.uid));
    }
    return;
  }

  @Action(SelectChat, { cancelUncompleted: true })
  async selectChat(ctx: StateContext<ChatStateModel>, action: SelectChat) {
    return this.chatRepository.get(action.chatUid).pipe(
      tap((selectedChat) => {
        ctx.patchState({
          selectedChat: selectedChat,
        });

        if (
          selectedChat &&
          !selectedChat.readBy.includes(
            this.store.selectSnapshot(InGameState.myTeam)!.uid,
          )
        ) {
          return this.chatRepository.update({
            ...selectedChat,
            readBy: [
              ...(selectedChat.readBy ?? []),
              this.store.selectSnapshot(InGameState.myTeam)!.uid,
            ],
          });
        }
        return EMPTY;
      }),
      switchMap((chat) =>
        chat
          ? this.chatMessageRepository.getMessages(chat.uid).pipe(
              tap((messages) => {
                ctx.patchState({
                  ...ctx.getState(),
                  messages: {
                    ...ctx.getState().messages,
                    [chat.uid]: messages,
                  },
                });
              }),
              catchError((error) => {
                // Handle errors here, possibly dispatching an error action
                console.error('Error fetching messages:', error);
                return EMPTY;
              }),
            )
          : EMPTY,
      ),
      catchError((error) => {
        // Handle errors here, possibly dispatching an error action
        console.error('Error fetching chat:', error);
        return EMPTY;
      }),
    );
  }

  @Action(SaveMessageAction)
  async saveMessageAction(
    ctx: StateContext<ChatStateModel>,
    action: SaveMessageAction,
  ) {
    const selectedChat = ctx.getState().selectedChat;
    if (selectedChat) {
      await firstValueFrom(
        this.chatRepository.update({
          ...selectedChat,
          lastMessageAt: new Date(),
          lastMessage: action.message,
          readBy: [this.store.selectSnapshot(InGameState.myTeam)!.uid],
        }),
      );

      await firstValueFrom(
        this.chatMessageRepository.create(
          {
            uid: GuidUtils.generateUuid(),
            message: action.message,
            senderUid: this.store.selectSnapshot(InGameState.myTeam)!.uid,
          },
          {
            chatUid: selectedChat.uid,
          },
        ),
      );
    }
  }
}

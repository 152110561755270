import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import { GuidUtils } from '@freddy/common';
import { Router } from '@angular/router';
import { CommandRepository } from '../repository/command.repository';
import {
  CloseChallengeCurtainAction,
  GoToChallengeAction,
  ListenAnswersAction,
  OpenChallengeCurtainAction,
  StartChallengeAction,
  SubmitChallengeAnswerAction,
} from '../../game/actions/game.actions';
import { append, patch } from '@ngxs/store/operators';
import { TeamChallengeRepository } from '../repository/team-challenge.repository';
import { InGameState } from './in-game.store';
import { ChallengeAnswerRepository } from '../repository/challengeAnswer.repository';
import { switchMap, tap } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { ChallengeAnswer } from '@freddy/models';
import { TeamRepository } from '../repository/team.repository';
import { MissionState } from './mission.store';

export const CHALLENGE_STATE_TOKEN = new StateToken<ChallengeStateModel>(
  'challenge',
);

export interface ChallengeStateModel {
  curtainOpen: boolean;
  challengeAnswers: ChallengeAnswer[];
  loading: boolean;
}

const defaultFormValue = {
  curtainOpen: true,
  challengeAnswers: [],
  loading: false,
};

@State<ChallengeStateModel>({
  name: CHALLENGE_STATE_TOKEN,
  defaults: {
    ...defaultFormValue,
  },
})
@Injectable()
export class ChallengeState {
  private readonly router = inject(Router);
  private readonly commandRepository = inject(CommandRepository);
  private readonly teamChallengeRepository = inject(TeamChallengeRepository);
  private readonly challengeAnswerRepository = inject(ChallengeAnswerRepository);
  private readonly teamRepository = inject(TeamRepository);
  private readonly store = inject(Store);


  @Selector()
  static challengeCurtainIsOpen(state: ChallengeStateModel): boolean {
    return state.curtainOpen;
  }

  @Selector()
  static challengeAnswer(state: ChallengeStateModel): ChallengeAnswer[] {
    return state.challengeAnswers;
  }

  static challengeAnswerFor(teamUid: string) {
    return createSelector(
      [ChallengeState.challengeAnswer],
      (challengeAnswer: ChallengeAnswer[]) => {
        return challengeAnswer.filter(
          (challengeAnswer) => challengeAnswer.teamUid === teamUid,
        );
      },
    );
  }

  @Action(OpenChallengeCurtainAction)
  openChallengeCurtainAction(
    ctx: StateContext<ChallengeStateModel>,
    action: CloseChallengeCurtainAction,
  ) {
    ctx.setState(
      patch<ChallengeStateModel>({
        curtainOpen: true,
      }),
    );
  }

  @Action(StartChallengeAction)
  startChallengeAction(
    ctx: StateContext<ChallengeStateModel>,
    action: StartChallengeAction,
  ) {
    return this.teamChallengeRepository
      .getChallenge(action.challenger, action.opponent, action.challenge.uid)
      .pipe(
        switchMap(async (teamChallenges) => {
          const uid =
            teamChallenges.length > 0
              ? teamChallenges[0].uid
              : GuidUtils.generateUuid();

          // Only add a new challenge if it does not exist.
          if (teamChallenges.length === 0) {
            await firstValueFrom(
              this.teamChallengeRepository.add({
                uid: uid,
                challengerUid: action.challenger,
                opponentUid: action.opponent,
                challengeUid: action.challenge.uid,
              }),
            );
          }

          // Prepare and send the GoToChallengeAction command.
          const goToChallengeAction = new GoToChallengeAction(uid);
          await firstValueFrom(
            this.commandRepository.sendCommand({
              uid: GuidUtils.generateUuid(),
              recipientTeamUid: action.opponent,
              action: goToChallengeAction,
              executed: false,
            }),
          );

          // Dispatch the GoToChallengeAction for local state management.
          this.store.dispatch(goToChallengeAction);

          // Returning an observable of null or similar as switchMap expects an Observable return.
          return of(null);
        }),
      );
  }

  @Action(GoToChallengeAction)
  goToChallengeAction(
    ctx: StateContext<ChallengeStateModel>,
    action: GoToChallengeAction,
  ) {
    return this.router.navigate(
      [
        'game',
        this.store.selectSnapshot(InGameState.game)?.code,
        'challenges',
        action.teamChallengeUid,
        'intro',
      ],
      { skipLocationChange: true },
    );
  }

  @Action(CloseChallengeCurtainAction)
  closeChallengeCurtainAction(
    ctx: StateContext<ChallengeStateModel>,
    action: CloseChallengeCurtainAction,
  ) {
    ctx.setState(
      patch<ChallengeStateModel>({
        curtainOpen: false,
      }),
    );
  }

  @Action(SubmitChallengeAnswerAction)
  async submitChallengeAnswerAction(
    ctx: StateContext<ChallengeStateModel>,
    action: SubmitChallengeAnswerAction,
  ) {
    const myTeam = this.store.selectSnapshot(InGameState.myTeam);
    if (!myTeam) {
      return;
    }
    const existingChallenge = await firstValueFrom(
      this.challengeAnswerRepository.getAnswer(
        action.teamChallenge.challengeUid,
        myTeam.uid,
      ),
    );

    if (existingChallenge.length === 0) {
      const challengeAnswer: ChallengeAnswer = {
        uid: GuidUtils.generateUuid(),
        isChallenger: action.teamChallenge.challengerUid === myTeam.uid,
        challengeUid: action.teamChallenge.challengeUid,
        correctAnswer: this.store.selectSnapshot(
          MissionState.specificAnswer(action.teamChallenge.challengeUid),
        ),
        points: this.store.selectSnapshot(
          MissionState.specificChallenge(action.teamChallenge.challengeUid),
        )?.common.points,
        teamUid: myTeam.uid,
        answer: action.answer,
        timeLeft: action.timeLeft,
        isEvaluated: false,
      };
      this.challengeAnswerRepository.add(challengeAnswer);
      this.teamRepository.update({
        uid: myTeam.uid,
        challengeAccomplished: [
          ...(myTeam.challengeAccomplished ?? []),
          action.teamChallenge.challengeUid,
        ],
      });
      ctx.setState(
        patch<ChallengeStateModel>({
          challengeAnswers: append<ChallengeAnswer>([challengeAnswer]),
          loading: false,
        }),
      );
    }

    return this.router.navigate(
      [
        'game',
        this.store.selectSnapshot(InGameState.game)?.code,
        'challenges',
        action.teamChallenge.uid,
        'result',
      ],
      { skipLocationChange: true },
    );
  }

  @Action(ListenAnswersAction, { cancelUncompleted: true })
  listenAnswersAction(ctx: StateContext<ChallengeStateModel>) {
    return this.challengeAnswerRepository.getCollectionsChanges().pipe(
      tap((answers) => {
        if (answers) {
          ctx.patchState({
            challengeAnswers: answers,
          });
        }
      }),
    );
  }
}

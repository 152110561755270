import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular/standalone';

import { CustomPreloadingStrategyService } from './core/preload/preload.strategy';


const routes: Routes = [
  {
    path: '',
    data: {
      preload: true,
    },
    loadChildren: () =>
      import('./connect/connect.module').then((m) => m.ConnectModule),
  },
  {
    path: 'game',
    data: {
      preload: true,
      loadAfterSeconds: 10,
    },
    loadChildren: () => import('./game/game.module').then((m) => m.GameModule),
  },
  {
    path: 'privacy-policy',
    loadComponent: () => import('./core/components/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent),
  },
  {
    path: ':organizationId/leaderboard/:gameId',
    loadComponent: () => import('./leaderboard/leaderboard.component').then(m => m.LeaderboardComponent),
  },
];

@NgModule({
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategyService,
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { CommonRepositoryAbstract } from '@freddy/common';
import { InGameState } from '../stores/in-game.store';
import { Store } from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import { Answer } from '@freddy/models';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class AnswerRepository extends CommonRepositoryAbstract<Answer> {
  private readonly store = inject(Store);

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }
  protected getDocPath(): string {
    return `${this.store.selectSnapshot(InGameState.gamePath)}/answers`;
  }
}

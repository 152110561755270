import { Injectable, inject } from '@angular/core';
import { CommonRepositoryAbstract, FirebaseUtils } from '@freddy/common';
import { Store } from '@ngxs/store';
import { InGameState } from '../stores/in-game.store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirebaseChat } from '@freddy/models';
import { Firestore, orderBy, where } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class ChatRepository extends CommonRepositoryAbstract<FirebaseChat> {
  private readonly store = inject(Store);

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  public getChats(participantUid: string): Observable<FirebaseChat[]> {
    // First query: Chats where the participant is involved
    const participantChats$ = this.getCollectionsChanges(undefined, [
      where('participants', 'array-contains', participantUid),
      orderBy('lastMessageAt', 'desc'),
    ]);

    // Second query: Chats that are channels
    const channelChats$ = this.getCollectionsChanges(undefined, [
      where('isChannel', '==', true),
      orderBy('lastMessageAt', 'desc'),
    ]);

    // Merging the two observables
    return combineLatest([participantChats$, channelChats$])
      .pipe(
        map(([participantChats, channelChats]) => {
          return [...participantChats, ...channelChats];
        }),
      )
      .pipe(
        map((chats) => chats.map((chat) => FirebaseUtils.convertDate(chat))),
      );
  }

  protected getDocPath(): string {
    return `${this.store.selectSnapshot(InGameState.gamePath)}/chats`;
  }
}

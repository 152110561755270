import { Injectable, inject } from '@angular/core';
import { CommonRepositoryAbstract } from '@freddy/common';
import { Store } from '@ngxs/store';
import { InGameState } from '../stores/in-game.store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Game, GameStatus } from '@freddy/models';
import { ORGANIZATION_PREFIX_PATH } from './config';
import {
  collectionGroup,
  Firestore,
  getDocs,
  limit,
  query,
  QueryDocumentSnapshot,
  where,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class GameRepository extends CommonRepositoryAbstract<Game> {
  private readonly store = inject(Store);

  static DOC_PATH = 'games';

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  public fetchGame(
    code: string,
  ): Observable<QueryDocumentSnapshot<Game> | null> {
    const gameQuery = query(
      collectionGroup(this.firestore, 'games'),
      where('code', '==', code),
      limit(1),
    );

    return from(getDocs(gameQuery)).pipe(
      map((gamesDocs) => {
        if (gamesDocs.size > 1) {
          console.error(
            'An error has occurred. Only one game should exist with that code',
          );
          return null;
        }
        return gamesDocs.docs[0] || null; // Adjusted to return the first doc or null
      }),
    ) as Observable<QueryDocumentSnapshot<Game> | null>;
  }

  public getOrganizationPrefixPath(): string {
    const org = this.store.selectSnapshot(InGameState?.currentOrganizationSlug);
    return org
      ? `${ORGANIZATION_PREFIX_PATH}/${this.store.selectSnapshot(InGameState?.currentOrganizationSlug)}/`
      : '';
  }

  public isGameJoinable(code: string): Observable<boolean> {
    const gameQuery = query(
      collectionGroup(this.firestore, 'games'),
      where('code', '==', code),
      limit(1),
    );

    return from(getDocs(gameQuery)).pipe(
      map((gamesDocs) => {
        if (gamesDocs.empty) {
          return false;
        }

        const game = gamesDocs.docs[0].data() as Game;

        // Check if game exists and is in a joinable status
        const joinableStatuses = [
          GameStatus.ONGOING,
          GameStatus.PLANNED,
          GameStatus.PAUSE,
        ];

        return joinableStatuses.includes(game.status);
      }),
    );
  }

  protected getDocPath(): string {
    return this.getOrganizationPrefixPath() + GameRepository.DOC_PATH;
  }
}

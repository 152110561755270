import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Answer, Mission, MissionTypeEnum } from '@freddy/models';

import { InGameState } from '../../core/stores/in-game.store';
import { FirebaseStorageService, LocalStorageService } from '@freddy/common';

@Injectable({
  providedIn: 'root',
})
export class MissionService {
  private readonly store = inject(Store);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly firebaseStorageService = inject(FirebaseStorageService);

  async handleMissionAnswer(
    response: Answer,
    mission: Mission,
  ): Promise<Answer> {
    const currentTeamPath = this.store.selectSnapshot(InGameState.teamPath);
    const answer = {
      ...response,
    };
    switch (answer.missionType) {
      case MissionTypeEnum.AUDIO: {
        const recordDataBase64: string = await this.localStorageService.get(
          answer.missionUid as string,
        );
        if (recordDataBase64) {
          const firebaseFile = await this.firebaseStorageService.uploadPathBlob(
            `${currentTeamPath}/answers/`,
            recordDataBase64,
          );
          answer.answer = firebaseFile.filePath;
        }
        break;
      }
      case MissionTypeEnum.VIDEO: {
        const video: Blob = await this.localStorageService.get(
          answer.missionUid as string,
        );
        if (video) {
          const firebaseFile = await this.firebaseStorageService.uploadBlob(
            `${currentTeamPath}/answers/`,
            video,
          );
          answer.answer = firebaseFile.filePath;
        }
        break;
      }
      case MissionTypeEnum.AR:
      case MissionTypeEnum.PHOTO: {
        if (answer.answer) {
          const firebaseFile = await this.firebaseStorageService.uploadPathBlob(
            `${currentTeamPath}/answers/`,
            answer.answer,
          );
          answer.answer = firebaseFile.filePath;
        }
        break;
      }
      case MissionTypeEnum.MULTIPLE_CHOICES: {
        answer.isEvaluated = true;
        const isCorrect =
          answer.answer ===
          mission?.metadata[MissionTypeEnum.MULTIPLE_CHOICES]?.selectedLine;
        if (isCorrect) {
          answer.points = mission?.common.points;
        } else {
          answer.points = 0;
        }
        break;
      }
      case MissionTypeEnum.OPEN: {
        break;
      }
      case MissionTypeEnum.GUIDED: {
        answer.isEvaluated = true;
        answer.points = answer.answer;
        break;
      }
      case MissionTypeEnum.MIN_MAX: {
        const margin = mission?.metadata['MIN_MAX']?.margin ?? 0;
        const correctAnswer = mission?.metadata['MIN_MAX']?.answer ?? -1;
        const isCorrect =
          correctAnswer + margin >= answer.answer &&
          correctAnswer - margin <= answer.answer;
        if (isCorrect) {
          answer.points = mission?.common.points;
        } else {
          answer.points = 0;
        }
        answer.isEvaluated = true;
        break;
      }
      case MissionTypeEnum.SLIDING_PUZZLE: {
        if (answer.answer) {
          answer.points = mission?.common.points;
        }
      }
    }
    return answer;
  }
}
